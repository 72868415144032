<template>
  <div class="link-show">
    <div class="text-content">
      <div class="text-area">
        <h1>灵思 正在建设中</h1>
        <p>
          即将上线<span>·</span>敬请期待
        </p>
        <MtButton
          text="回到首页"
          class="goWorld"
          radius="8"
          @click.native="changeHome"
        ></MtButton>
      </div>
      <!-- <div class="banner"></div> -->
    </div>
  </div>
</template>

<script>
import MtButton from "@/components/mt-button/index.vue";
export default {
  components: {
    MtButton,
  },
  methods: {
    changeHome() {
      this.$router.push('/');
    },
  },
};
</script>
<style lang="scss" scoped>
.link-show {
  // margin-top: 60px;
  width: 100%;
  height: 928px;
  background-size: cover;
  // background-attachment: fixed;
  background-position: center 0;
  color: #333439;
  background-image: url("../../../../../assets/waiting.png");
  .text-content {
    display: flex;
    // align-items: center;
    width: 1200px;
    height:100%;
    margin: 0 auto;
    .text-area {  
      margin-top: 331px;   
      h1 {
        font-weight: 700;
        font-size: 48px;
        line-height: 70px;
        white-space: nowrap;
      }
      p {
        margin-top: 28px;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        span {
          margin: 0 15px;
        }
      }
      .goWorld {
        margin-top: 87px;
      }
    }
    //666*928
    // .banner {
    //   width: 881px;
    //   height: 750px;
    //   margin-left: 53px;
    //   background-size: cover;
    //   // background-image: url("../../../../../assets/waiting.png");
    // }
  }
}
</style>
